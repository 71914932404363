import postcodesToState from "assets/lookups/states";
import dayjs from "lib/dayjs";
import {
    parsePhoneNumberFromString,
    parseIncompletePhoneNumber,
} from "libphonenumber-js";
import { TFunction } from "i18next";

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript/46181#46181
// prettier-ignore
// eslint-disable-next-line no-useless-escape
export const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

// This regex allows all characters except for the specified special characters
// Should be consistent with what is used in user/profile and onboarding page name validation.
export const nameRegex = /^[^*|":<>[\]{}\\~!_();,^@#%&$+=?]+$/;

// Trim the name and test it against the regex pattern
export const isValidUserName = (name?: string): boolean => {
    if (!name) {
        return false;
    }
    return nameRegex.test(name.trim());
};

export const isValidEmailAddress = (emailAddress: string): boolean => {
    return emailRegex.test(emailAddress.toLowerCase());
};

export const isValidPhoneNumber = (value: string): boolean => {
    const phoneNumber = parsePhoneNumberFromString(value, "MY");
    return !!(phoneNumber && phoneNumber.isValid());
};

export const validateUserName = (_: Rule, value: string): Promise<void> => {
    if (value && value !== "") {
        try {
            if (isValidUserName(value)) {
                return Promise.resolve();
            }
            return Promise.reject("Invalid Name!");
        } catch (err) {
            return Promise.reject(err);
        }
    }
    return Promise.resolve();
};

type Rule = {
    [key: string]: string;
};
export const validatePhoneNumber = (_: Rule, value: string): Promise<void> => {
    if (value && value !== "") {
        try {
            const phoneNumber = parsePhoneNumberFromString(value, "MY");
            if (phoneNumber && phoneNumber.isValid()) {
                return Promise.resolve();
            }

            return Promise.reject("Invalid Phone Number!");
        } catch (err) {
            return Promise.reject(err);
        }
    }

    return Promise.resolve();
};

export const formatPhoneNumber = (value: string): string => {
    if (!value) {
        return value;
    }

    const formattedNumber = parseIncompletePhoneNumber(value);

    try {
        const phoneNumber = parsePhoneNumberFromString(formattedNumber, "MY");

        if (phoneNumber && phoneNumber.isValid() === true) {
            return phoneNumber.format("E.164");
        }
    } catch (err) {
        return formattedNumber;
    }

    return formattedNumber;
};

export const convertStringToTitleCase = (input?: string): string => {
    if (!input) {
        return "";
    }
    const titleString = input
        .split(" ")
        .map((x) => {
            if (!x[0]) {
                return x;
            }
            return x[0].toUpperCase() + x.slice(1).toLowerCase();
        })
        .join(" ");
    return titleString;
};

type Location = {
    name: string | TFunction;
    city?: string;
};
type GroupedLocationsByState<T extends Location> = {
    state: string;
    locations: T[];
};
export const getGroupedLocationsByState = <T extends Location>(
    locations: T[],
): GroupedLocationsByState<T>[] => {
    const locationsWithState = locations.map((location) => {
        // Currently using this method as the other data source, which is https://github.com/atqnp/postcode-malaysia, also does not include "Pudu"/"Seputeh" on its own. Esp Seputeh which has different variations of it in different states, and not sure how to pinpoint it to "Seputeh" only.
        let child = location.city ?? "";
        for (const state of postcodesToState) {
            if (state.city.some((sc) => sc.name === location.name)) {
                child = state.name;
            }
        }
        return {
            ...location,
            state: child,
        };
    });

    return locationsWithState.reduce<GroupedLocationsByState<T>[]>(
        (acc, location) => {
            const state = location.state;
            const group = acc.find((group) => group.state === state);
            if (!group) {
                acc.push({ state, locations: [location] });
            } else {
                group.locations.push(location);
            }
            return acc;
        },
        [],
    );
};

export const validatePostcode = (_: Rule, postcode: string): Promise<void> => {
    if (postcode && postcode !== "") {
        try {
            if (
                postcodesToState.some((state) =>
                    state.city.some((c) =>
                        c.postcode.some((pc) => pc === postcode),
                    ),
                )
            ) {
                return Promise.resolve();
            }
            return Promise.reject("Invalid Postcode");
        } catch (err) {
            return Promise.reject(err);
        }
    }
    return Promise.resolve();
};

export const formatCents = (priceCents: number): string => {
    if (priceCents === 0) return "RM 0.00";
    if (!priceCents || isNaN(priceCents)) return "";
    return "RM " + (priceCents / 100).toFixed(2);
};

export const debounce = <T>(
    func: React.Dispatch<React.SetStateAction<T>>,
    wait = 300,
    immediate = false,
): (() => void) => {
    let timeout;
    return function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        const later = (): void => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const formatDateRange = (startDt?: string, endDt?: string): string => {
    if (!startDt || !endDt) return "";
    const isSingleDay = formatDate(startDt) == formatDate(endDt);
    if (isSingleDay)
        return `${formatDate(startDt)} ${formatTime(startDt)} - ${formatTime(
            endDt,
        )}`;
    return `${formatDate(startDt)} - ${formatDate(endDt)}`;
};

export const formatTime = (d: string): string => {
    return dayjs(d).tz().format("hh:mm a");
};

export const formatDate = (d: string): string => {
    return dayjs(d).tz().format("DD MMMM YYYY");
};

export const byCategoryImportance = <T extends { name: string }>(
    a: T,
    b: T,
): number => {
    // The order matters, this makes sure badminton will be above futsal, and those 2 above everything else, others will be alphabetical
    const an = a.name.toLowerCase();
    const bn = b.name.toLowerCase();
    if (an === "badminton") return -1;
    if (bn === "badminton") return 1;
    if (an === "futsal") return -1;
    if (bn === "futsal") return 1;
    return an.localeCompare(bn);
};

export const byStateImportance = <T extends { state: string }>(
    a: T,
    b: T,
): number => {
    const an = a.state.toLowerCase();
    const bn = b.state.toLowerCase();
    if (an === "selangor") return -1;
    if (bn === "selangor") return 1;
    if (an === "w.p kuala lumpur") return -1;
    if (bn === "w.p kuala lumpur") return 1;
    if (an === "negeri sembilan") return -1;
    if (bn === "negeri sembilan") return 1;
    if (an === "johor") return -1;
    if (bn === "johor") return 1;
    if (an === "perak") return -1;
    if (bn === "perak") return 1;
    if (an === "pahang") return -1;
    if (bn === "pahang") return 1;
    if (an === "sabah") return -1;
    if (bn === "sabah") return 1;
    if (an === "sarawak") return -1;
    if (bn === "sarawak") return 1;
    if (an === "kedah") return -1;
    if (bn === "kedah") return 1;
    if (an === "penang") return -1;
    if (bn === "penang") return 1;
    if (an === "perlis") return -1;
    if (bn === "perlis") return 1;
    return an.localeCompare(bn);
};

export const getServiceStartEnd = (
    st: dayjs.Dayjs,
    d: number,
    svc: { serviceMode: string; startTime?: string; endTime?: string },
): [string, string] => {
    let ed = st.add(d, "h");
    if (svc.serviceMode === "DAILY_SERVICE") {
        const [sh, sm] = parseTime(svc.startTime ?? "12:00");
        const [eh, em] = parseTime(svc.endTime ?? "12:00");
        st = st.hour(sh).minute(sm);
        ed = ed.hour(eh).minute(em);
    }
    return [st.toISOString(), ed.toISOString()];
};

export const parseTime = (v: string): [number, number] => {
    const [h, m] = v.split(":");
    return [parseInt(h ?? "0"), parseInt(m ?? "0")];
};

/**
 * Intended to be used with Array methods such as `filter` to get unique
 * values. It uses `indexOf` as a way to perform the comparison. As such
 * it will use strict equality(`===`) as the way to compare values. From
 * that, use this for primitive values and not Objects.
 *
 * Example usage:
 * ```ts
 * const cities = ["KL", "KL", "PJ", "SJ"];
 *
 * console.log(cities.filter(isUnique));
 * // ["KL", "PJ", "SJ"]
 * ```
 * @param v current value
 * @param i index of current value
 * @param arr whole array
 * @returns true if current value is the first occurence in the whole array
 */
export const isUnique = <T>(v: T, i: number, arr: T[]): boolean =>
    arr.indexOf(v) === i;

/**
 * Intended to be used with Array methods such as `filter` to get unique
 * values by the value returned by the given `getter`. The `getter` should
 * return a primitive value as it is then compared using strict
 * equality(`===`).
 *
 * Example usage:
 * ```ts
 * const arr = [
 *     {category: "Badminton", city: "KL"},
 *     {category: "Badminton", city: "PJ"},
 *     {category: "Futsal", city: "KL"},
 *     {category: "Basketball", city: "KL"},
 * ];
 *
 * console.log(arr.filter(isUniqueBy((v) => v.category)));
 * //[
 * //    {category: "Badminton", city: "KL"},
 * //    {category: "Futsal", city: "KL"},
 * //    {category: "Basketball", city: "KL"},
 * //]
 * console.log(arr.filter(isUniqueBy((v) => v.city)));
 * //[
 * //    {category: "Badminton", city: "KL"},
 * //    {category: "Badminton", city: "PJ"},
 * //]
 * ```
 * @param getter method to get the value to compare by
 * @returns a function that performs a uniqueness check
 */
export const isUniqueBy = <T, U>(
    getter: (v: T) => U,
): ((v: T, i: number, arr: T[]) => boolean) => {
    return (current, i, arr) => {
        return arr.findIndex((v) => getter(v) === getter(current)) === i;
    };
};

/**
 * Intended to be used with Array methods like `filter` to get non-nullish
 * values. It performs `!!v` as the check.
 *
 * Example usage:
 * ```ts
 * const animals = ["fish", "cat", "", undefined, "dog"];
 *
 * console.log(animals.filter(isNonNullable));
 * // ["fish", "cat", "dog"]
 * ```
 * @param v current value
 * @returns true if current value is true-y
 */
export const isNonNullable = <T>(v: T): v is NonNullable<T> => !!v;

/**
 * Returns unique values of an array based on Same-Value-Zero equality (uses `Set` internally)
 *
 * Example usage:
 * ```ts
 * const cities = ["KL", "KL", "PJ", "SJ"];
 *
 * console.log(unique(cities));
 * // ["KL", "PJ", "SJ"]
 * ```
 * @param arr array to get unique values of
 * @returns new array with only unique values based on Same-Value-Zero equality
 */
export const unique = <T>(arr: T[]): T[] => {
    return Array.from(new Set(arr));
};
